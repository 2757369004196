import { useAuthStore } from '~~/stores/auth'

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore()
  const router = useRouter()
  const isLoggedIn = computed(() => authStore?.state?.isLoggedIn)

  if (!isLoggedIn.value) {
    return router.push('/')
  }
})
